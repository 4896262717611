var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"mb-6"},[_c('div',{staticClass:"heading d-flex align-center mb-3"},[_c('h3',{staticClass:"title"},[_vm._v(" Search bot events ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"rounded-0 text-caption mr-2",attrs:{"solo":"","dense":"","hide-details":"","flat":"","placeholder":"Search by bot name or event hash","background-color":"grey800"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"unwidth unheight align-self-stretch",attrs:{"color":"grey300","tile":"","small":"","ripple":false,"loading":_vm.loading},on:{"click":_vm.fetchApiData}},[_c('fai',{staticClass:"grey100--text",attrs:{"icon":['fas','sync']}})],1)],1),_c('v-data-table',{staticClass:"rounded-0 data-table",attrs:{"headers":_vm.headers,"items":_vm.entries,"options":_vm.options,"footer-props":{ itemsPerPageOptions: [5, 10, 25] },"server-items-length":_vm.total,"expanded":_vm.expanded,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event},"pagination":_vm.onPagination},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('h5',{staticClass:"font-weight-semibold blue300--text"},[_vm._v(" "+_vm._s(_vm.TYPES_EVENT[value] || value)+" ")]),_c('h6',{staticClass:"event-hash font-weight-regular text--secondary link",domProps:{"textContent":_vm._s(item.hash)},on:{"click":function($event){return _vm.onCopy(item.hash)}}})]}},{key:"item.createdAt",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("relativeTime")(new Date(value).getTime(),'twitter')))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("toDateFormat")(new Date(value).getTime(),'YYYY-MM-DD HH:mm:ss')))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('fai',{staticClass:"text-body-1",class:!!item.data ? 'blue400--text link' : 'text--disabled',attrs:{"icon":['fad', 'info-square']},on:{"click":function($event){!!item.data && _vm.onExpandItem(item)}}})]}},{key:"expanded-item",fn:function(ref){
var head = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"grey800 py-3 data-detail",attrs:{"colspan":head.length}},[_c('div',{staticClass:"d-flex justify-end mb-3"},[(item.data)?_c('v-btn',{staticClass:"rounded-0",attrs:{"color":"blue700 blue300--text","depressed":"","ripple":false,"x-small":""},on:{"click":function($event){_vm.onCopy(JSON.stringify(item.data))}}},[_vm._v(" Copy JSON ")]):_vm._e()],1),_c('pre',{domProps:{"textContent":_vm._s(item.data)}})])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }