<template>
  <div>
    <!-- user searching  -->
    <section class="mb-6">
      <div class="heading d-flex align-center mb-3">
        <h3 class="title">
          Search bot events
        </h3>
        <v-spacer />
        <v-text-field
          v-model="search"
          solo dense hide-details flat
          class="rounded-0 text-caption mr-2"
          placeholder="Search by bot name or event hash"
          background-color="grey800"
        />
        <v-btn
          color="grey300"
          tile small class="unwidth unheight align-self-stretch"
          :ripple="false" :loading="loading"
          @click="fetchApiData"
        >
          <fai :icon="['fas','sync']" class="grey100--text" />
        </v-btn>
      </div>

      <v-data-table
        class="rounded-0 data-table"
        :headers="headers"
        :items="entries"
        :options.sync="options"
        :footer-props="{ itemsPerPageOptions: [5, 10, 25] }"
        :server-items-length="total"
        :expanded="expanded"
        :loading="loading"
        @pagination="onPagination"
      >
        <template #item.type="{ value, item }">
          <h5 class="font-weight-semibold blue300--text">
            {{ TYPES_EVENT[value] || value }}
          </h5>
          <h6
            class="event-hash font-weight-regular text--secondary link"
            @click="onCopy(item.hash)"
            v-text="item.hash"
          />
        </template>

        <template #item.createdAt="{ value, item }">
          <v-tooltip top color="primary500">
            <template #activator="{on}">
              <span v-on="on">{{ new Date(value).getTime() | relativeTime('twitter') }}</span>
            </template>
            <span>{{ new Date(value).getTime() | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
          </v-tooltip>
        </template>

        <template #item.actions="{ item }">
          <fai
            :icon="['fad', 'info-square']"
            class="text-body-1"
            :class="!!item.data ? 'blue400--text link' : 'text--disabled'"
            @click="!!item.data && onExpandItem(item)"
          />
        </template>

        <template #expanded-item="{ headers: head, item }">
          <td :colspan="head.length" class="grey800 py-3 data-detail">
            <div class="d-flex justify-end mb-3">
              <v-btn
                v-if="item.data"
                color="blue700 blue300--text"
                class="rounded-0"

                depressed :ripple="false" x-small
                @click="onCopy(JSON.stringify(item.data))"
              >
                Copy JSON
              </v-btn>
            </div>
            <pre v-text="item.data" />
          </td>
        </template>
      </v-data-table>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce, invertObject } from '@/utils'

const EVENT_TYPES = {
  LoggedOn: 1,
  WebSession: 2,
  AccountInfo: 3,
  AccountLimitations: 4,
  Wallet: 5,

  NewOffer: 30,
  SentOfferChanged: 31,
  SentOfferCanceled: 32,
  SentPendingOfferCanceled: 33,
  UnknownOfferSent: 34,
  ReceivedOfferChanged: 35,
  PollFailure: 36,
  PollData: 37,
  OfferList: 38,

  Error: 99,
  CannotTrade: 100,
  SteamGuard: 101,
  Disconnected: 102,
  SessionExpired: 103,
}

const TYPES_EVENT = invertObject(EVENT_TYPES)

export default {
  data() {
    return {
      loading: false,
      total: 0,
      search: '',
      expanded: [],
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        itemsPerPage: 10,
        mustSort: true,
      },
      headers: [
        { text: 'Bot', value: 'bot', align: 'start' },
        {
          text: 'Event', value: 'type', align: 'start',
        },
        {
          text: 'Created', value: 'createdAt', align: 'end',
        },
        {
          text: null, value: 'actions', sortable: false, align: 'end',
        },
      ],
      entries: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
    TYPES_EVENT() {
      return TYPES_EVENT
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
      deep: true,
    },
    search: {
      handler(val) {
        this.onSearchInput(val)
      },
      deep: true,
    },
  },
  methods: {
    onSearchInput: debounce(function fn(val) {
      this.fetchApiData()
    }, 500),
    async fetchApiData() {
      this.loading = true

      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options

      try {
        const { entries, total } = await this.$socket.request('admin.bots.events', {
          page,
          sortBy: sortBy[0],
          asc: !sortDesc[0],
          limit: itemsPerPage,
          search: this.search,
        })

        this.entries = entries.map(i => ({ ...i, id: i._id }))
        this.total = total
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    onExpandItem(item) {
      const [expandedItem] = this.expanded

      if (item.id === expandedItem?.id) {
        this.expanded = []
      } else {
        this.expanded = [item]
      }
    },
    onPagination() {
      this.expanded = []
    },
    async onCopy(text) {
      try {
        await navigator.clipboard.writeText(text)
        this.$toast.info('Copied')
      } catch (err) {
        this.$toast.error('Copy failed, sorry.')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }
}

.user-obj {
  overflow: auto;
}

.event-hash {
  font-size: 10px;
}

.data-detail {
  pre {
    overflow-x: auto;
    max-height: 250px;
    white-space: pre-wrap;
    word-break: break-all;
  }
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    border-radius: 0;
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
